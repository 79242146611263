import React from "react";
import { Route, Routes, Navigate } from 'react-router-dom';
import HomePage from "./pages/HomePage";
import DiscoverPage from "./pages/DiscoverPage"; // Ensure you have this component
import ProfilePage from "./pages/ProfilePage"; // Ensure you have this component

export const RouteContent: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/discover" element={<DiscoverPage />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/" element={<Navigate replace to="/" />} />
      {/* You can add more routes as needed */}
    </Routes>
  );
};