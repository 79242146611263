import React from 'react';

const ProfilePage: React.FC = () => {
  return (
    <div className="container mx-auto px-4">
      <h1 className="text-4xl font-bold text-center p-32 text-neutralBlack">WELCOME TO MY PROFILE PAGE</h1>
    </div>
  );
}

export default ProfilePage;