import React from 'react';
import './assets/styles/app.css';
import { RouteContent } from './routes';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  return (
    <BrowserRouter>

      <RouteContent />

    </BrowserRouter>
  );
}

export default App;
