import React, { FormEvent, useState, useEffect } from 'react';
import logo from '../assets/images/postfolio-logo-blue.png';
import darrel from '../assets/images/darrel.jpg';
import angeli from '../assets/images/angeli.jpg';
import anthony from '../assets/images/anthony.jpg';
import emailjs from 'emailjs-com';

const HomePage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State to manage success modal visibility
  const [showErrorModal, setShowErrorModal] = useState(false); // State to manage error modal visibility
  const [modalClass, setModalClass] = useState('');
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (showSuccessModal || showErrorModal) {
      setModalClass('modal-show');
      setTimeout(() => {
        setModalClass('modal-hide');
        setTimeout(() => {
          setShowSuccessModal(false);
          setShowErrorModal(false); // Reset both modals after fade out
        }, 1000); // Wait for fade out to finish
      }, 5000); // Display modal for 5 seconds then start fade out
    }
  }, [showSuccessModal, showErrorModal]); // React to changes in either modal

  const sendEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the native form submission

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    setIsSending(true); // Email is being sent

    const form = e.currentTarget;
    emailjs.sendForm('service_6e89pau', 'template_k0kx3tq', form, 'VxY9czTrZGZ93Ddve')
      .then((result) => {
        console.log(result.text);
        setShowSuccessModal(true); // Show the success modal on successful email submission
        setEmail(''); // Clear the email input after successful submission
      }, (error) => {
        console.log(error.text);
        setShowErrorModal(true); // Show the error modal on failure
      })
      .finally(() => {
        setIsSending(false); // Email sending is complete (success or fail)
      });
  };

  return (
    <div className="bg-[#fafafa] min-h-screen pt-10">
      <div className="max-w-screen-xl mx-auto">
        <header>
          <div className="logo">
            <img className="w-60 object-contain" src={logo} alt="logo" />
          </div>
        </header>

        <div className="container">
          <h1 className='font-poppinsMedium text-secondary-base'>Free content collection tool for your personal interests.</h1>
          <p className='font-poppinsRegular'>Postfolio is a free tool that allows you to save posts from your favorite social media sites and organize them on your page. It's easy to use and you can start saving in minutes.</p>
          <form className="join-wrapper font-poppinsRegular" onSubmit={sendEmail}>
            <input type="email" name="user_email" id="email" placeholder="Enter your email" required pattern="\S+@\S+\.\S+" value={email} onChange={(e) => setEmail(e.target.value)} className="email-input" />
            <button 
              type="submit" 
              disabled={isSending} 
              className={`${isSending ? 'bg-[#cbd5e1] cursor-not-allowed' : 'bg-primary-base hover:bg-primary-hover active:bg-primary-pressed'} font-poppinsRegular text-white active:ring-2 active:ring-primary-50`}
            >
              {isSending ? 'Please Wait...' : 'Join waitlist'}
            </button>          </form>
          {showSuccessModal && (
            <div className={`fixed top-28 left-1/2 transform -translate-x-1/2 ${modalClass}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800" role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <span className="sr-only">Info</span>
            <div className=' font-poppinsRegular'>
              <span className="font-poppinsMedium">Success!</span> You have been added to the waitlist.
            </div>
            </div>
            </div>
          )}
          {showErrorModal && (
            <div className={`fixed top-28 left-1/2 transform -translate-x-1/2 ${modalClass}`}>
              <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800" role="alert">
                <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 100-2 1 1 0 000 2zm0-8a1 1 0 00-1 1v4a1 1 0 102 0V7a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
                <div className='font-poppinsRegular'>
                  <span className="font-poppinsMedium">Error!</span> There was an issue adding you to the waitlist. Please try again.
                </div>
              </div>
            </div>
          )}

          <div className="result"></div>
          <div className="creators pt-5">
            <div>
              <img src={darrel} alt="Darrel" />
              <img src={angeli} alt="Angeli" />
              <img src={anthony} alt="Anthony" />
            </div>
            <p className='font-poppinsRegular text-secondary-base'>Join 5.3k other creatives and get early access to beta.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
